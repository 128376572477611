import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Hero from "../components/sections/hero"
import { StaticQuery, graphql } from "gatsby"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const imageCopyright = [
	{
		filename: "termin_hero.jpg",
		copyright: "©Vadmary/depositphotos.com",
	},
	{
		filename: "kontakt_hero.jpg",
		copyright: "©oleg_chumakov/depositphotos.com",
	},
	{
		filename: "startseite_last_section.jpg",
		copyright: "©Rawpixel/depositphotos.com",
	},
	{
		filename: "startseite_big_images_5.jpg",
		copyright: "©SergeyNivens/depositphotos.com",
	},
	{
		filename: "startseite_big_images_4.jpg",
		copyright: "©Photocreo/depositphotos.com",
	},
	{
		filename: "startseite_big_images_3.jpg",
		copyright: "©alphaspirit/depositphotos.com",
	},
	{
		filename: "startseite_big_images_2.jpg",
		copyright: "©stockasso/depositphotos.com",
	},
	{
		filename: "startseite_big_images_1.jpg",
		copyright: "©vova130555@gmail.com/depositphotos.com",
	},
	{
		filename: "starsteite_long.jpg",
		copyright: "©digitalstorm/depositphotos.com",
	},
	{
		filename: "startseite_hero.jpg",
		copyright: "©ADragan/depositphotos.com",
	},
	{
		filename: "wir_hero.jpg",
		copyright: "©photoncatcher63/depositphotos.com",
	},
	{
		filename: "e-recruiting-analyse.png",
		copyright: "©MartaShershen/depositphotos.com",
	},
	{
		filename: "active-sourcing.png",
		copyright: "©Variant/depositphotos.com",
	},
	{
		filename: "recruiter-mieten.png",
		copyright: "©Irynaalex/depositphotos.com",
	},
	{
		filename: "motivation-kpi.png",
		copyright: "©vectorikart/depositphotos.com",
	},
	{
		filename: "recruiting-seminare.png",
		copyright: "©Lisitsa_/depositphotos.com",
	},
	{
		filename: "recruiting-strategie.png",
		copyright: "©bakhtiarzein/depositphotos.com",
	},
	{
		filename: "recruiting-impulse.png",
		copyright: "©bakhtiarzein/depositphotos.com",
	},
	{
		filename: "recruitervermittlung.png",
		copyright: "©Seamartini/depositphotos.com",
	},
	{
		filename: "das-zeichnet-uns-aus.png",
		copyright: "©TopVectors/depositphotos.com",
	},
	{
		filename: "karriere-website.png",
		copyright: "©mast3r/depositphotos.com",
	},
	{
		filename: "datenschutz_hero.png",
		copyright: "©vska/depositphotos.com",
	},
	{
		filename: "falke.png",
		copyright: "©Image licensed by Ingram/adpic",
	},
	{
		filename: "impressum_hero.png",
		copyright: "©Image licensed by Ingram/adpic",
	},
]

const Impressum = () => (
	<StaticQuery
		query={graphql`
			query {
				images: allFile {
					edges {
						node {
							relativePath
							childImageSharp {
								gatsbyImageData(placeholder: TRACED_SVG)
							}
						}
					}
				}
				hero: file(relativePath: { eq: "impressum_hero.png" }) {
					childImageSharp {
						gatsbyImageData(placeholder: TRACED_SVG)
					}
				}
			}
		`}
		render={(data) => (
			<SimpleReactLightbox>
				<Layout>
					<Seo noIndex={true} />

					<Hero
						data={{
							h1: "Impressum",
							image: "impressum_hero.jpg",
							imageNode: data.hero,
						}}
					></Hero>

					<div className="sectionwidth sectionpadding" id="impressum">
						<div className="prose prose-xl prose-primary sectionwidth">
							<p>Internes-Recruiting.de ist ein Label der:</p>
							<p>
								<strong>D.I.E. STRATEGIE GmbH</strong>
								<br />
								Ensingerstr. 33/2
								<br />
								89073 Ulm
							</p>
							<p>
								<strong>E-Mail:</strong> hallo(at)internes-recruiting.de
								<br />
								<strong>Internet:</strong>{" "}
								<a href="https://www.internes-recruiting.de">
									www.internes-recruiting.de
								</a>
								<br />
								<strong>Tel.:</strong>&nbsp;
								<strong>
									<a href="tel:0731 1461789 1">0731 1461789 1</a>
								</strong>
							</p>
							<p>
								Geschäftsführer: Damir Koren
								<br />
								Registergericht: Amtsgericht Ulm
								<br />
								Registernummer: HRB 748810
								<br />
								Umsatzsteuer-Identifikationsnummer gemäß § 27 a
								Umsatzsteuergesetz: DE310597771
							</p>
							<p>
								Der besseren und vereinfachten Lesbarkeit halber verwenden wir
								bei bestimmten Begriffen ausschließlich die männliche
								Schreibweise. Selbstverständlich sind Frauen damit gleichermaßen
								angesprochen. Wir bitten um Ihr Verständnis.
							</p>
							<p>
								<strong>
									Hinweis nach §§ 36,37 Verbraucherschlichtungsgesetz:
								</strong>
							</p>
							<p>
								Die D.I.E. STRATEGIE GmbH ist nicht verpflichtet, an
								Streitbeilegungsverfahren vor einer
								Verbraucherschlichtungsstelle teilzunehmen und ist hierzu auch
								nicht bereit. Die D.I.E. STRATEGIE GmbH nimmt daher nicht an
								Streitbeilegungsverfahren vor einer
								Verbraucherschlichtungsstelle teil.
							</p>
							<p>
								<strong>Haftung für Inhalte</strong>
								<br />
								Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
								Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
								können wir jedoch keine Gewähr übernehmen.
							</p>
							<p>
								Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
								Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
								verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
								Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
								gespeicherte fremde Informationen zu überwachen oder nach
								Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
								hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
								Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
								hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
								ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
								möglich. Bei bekannt werden von entsprechenden
								Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
							</p>
							<p>
								<strong>Haftung für Links</strong>
								<br />
								Unser Angebot enthält Links zu externen Webseiten Dritter, auf
								deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
								diese fremden Inhalte auch keine Gewähr übernehmen. Für die
								Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
								oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
								wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
								überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
								Verlinkung nicht erkennbar. Eine permanente inhaltliche
								Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
								Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
								Bekanntwerden von Rechtsverletzungen werden wir derartige Links
								umgehend entfernen.
							</p>
							<p>
								<strong>Urheberrecht</strong>
								<br />
								Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
								diesen Seiten unterliegen dem deutschen Urheberrecht. Die
								Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
								Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
								schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
								Downloads und Kopien dieser Seite sind nur für den privaten,
								nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
								dieser Seite nicht vom Betreiber erstellt wurden, werden die
								Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
								Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
								Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
								entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
								werden wir derartige Inhalte umgehend entfernen.
							</p>
							<p>
								Wir bedanken uns für die Nutzung dieser Formulierungen bei
								eRecht24.de – Internetrecht von Rechtsanwalt Sören Siebert.
							</p>
							<p>
								Alle&nbsp;verwendeten Bilder &amp; Videos sind wie folgt
								lizenziert:
							</p>
						</div>

						<SRLWrapper>
							<div className="sectionwidth grid mt-8 gap-16 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
								{data?.images?.edges?.map((item, index) => {
									const copyright = imageCopyright.find((e) => {
										return item.node.relativePath.includes(e.filename)
									})?.copyright

									if (!copyright) {
										return null
									}

									return (
										<div
											key={index}
											className="flex items-center align-middle flex-col"
										>
											<GatsbyImage
												image={getImage(item.node)}
												alt={copyright}
												loading="lazy"
												className="rounded shadow-lg cursor-pointer hover:opacity-80 transition transform hover:scale-105"
											/>
											<div className="text-center break-all text-sm mt-2">
												{copyright}
											</div>
										</div>
									)
								})}
							</div>
						</SRLWrapper>
					</div>
				</Layout>
			</SimpleReactLightbox>
		)}
	/>
)

export default Impressum
